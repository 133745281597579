import {createMuiTheme, createStyles, darken, fade, lighten, makeStyles, Theme} from '@material-ui/core/styles'
import {VictoryTheme} from 'victory'

const defaultTheme = createMuiTheme({})

const colorTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#024935',
            dark: '#083626',
            light: '#E7E7E7',
        },
        secondary: {
            main: '#FF7F0B',
            light: '#FFE7D1',
        },
        info: {
            main: '#66E2BC',
            light: lighten('#66E2BC', 0.25),
            dark: darken('#66E2BC', 0.25),
        },
        success: {
            main: '#5CEE93',
            light: lighten('#5CEE93', 0.25),
            dark: darken('#5CEE93', 0.25),
        },
        warning: {
            main: '#FFDFC2',
            light: lighten('#FFDFC2', 0.25),
            dark: darken('#FFDFC2', 0.25),
        },
        error: {
            main: '#EE5C5C',
            light: lighten('#EE5C5C', 0.25),
            dark: darken('#EE5C5C', 0.25),
        },
        background: {
            default: '#F7FBFC',
        },
        divider: fade('#354B5F', 0.25),
        grey: {
            50: '#F7F7F8',
            100: '#EFF1F2',
            200: '#E4E7E9',
            300: '#D0D4D9',
            400: '#A0A8B0',
            500: '#7A858E',
            600: '#505A64',
            700: '#3D4851',
            800: '#252D35',
            900: '#101519',
            A100: '#C0C6CC',
            A200: '#88929B',
            A400: '#191F26',
            A700: '#3D4851',
        },
    },
})

export const typographyTheme = createMuiTheme({
    typography: {
        fontFamily: 'Sora, sans-serif',
        fontWeightBold: 600,
        h1: {
            fontFamily: 'Sora, sans-serif',
            fontWeight: 500,
            fontSize: '2.5rem',
            color: colorTheme.palette.primary.main,
            [defaultTheme.breakpoints.only('xs')]: {
                fontSize: '2rem',
            },
        },
        h2: {
            fontFamily: 'Sora, sans-serif',
            fontWeight: 500,
            fontSize: '1.5rem',
            color: colorTheme.palette.primary.main,
        },
        h3: {
            fontFamily: 'Sora, sans-serif',
            fontWeight: 500,
            fontSize: '1.25rem',
            color: colorTheme.palette.primary.main,
        },
        h4: {
            fontFamily: 'Sora, sans-serif',
            fontWeight: 400,
            fontSize: '1rem',
            color: colorTheme.palette.primary.main,
        },
        h5: {
            fontFamily: 'Sora, sans-serif',
            fontWeight: 500,
            fontSize: '0.71rem',
            color: colorTheme.palette.primary.main,
        },
        h6: {
            fontFamily: 'Sora, sans-serif',
            fontWeight: 500,
            fontSize: '0.625',
            color: colorTheme.palette.primary.main,
        },
        subtitle1: {
            fontFamily: 'Sora, sans-serif',
            fontWeight: 500,
            fontSize: '0.87rem',
            color: colorTheme.palette.primary.main,
        },
        subtitle2: {
            fontSize: '0.75rem',
        },
        body1: {
            fontSize: '0.875rem',
            fontWeight: 500,
        },
    },
})

export const shapeTheme = createMuiTheme({
    shape: {
        borderRadius: 6,
    },
})

export const theme = createMuiTheme({
    palette: {
        ...colorTheme.palette,
    },
    typography: {
        ...typographyTheme.typography,
    },
    shape: {
        ...shapeTheme.shape,
    },
    overrides: {
        MuiPaper: {
            outlined: {
                borderColor: colorTheme.palette.divider,
            },
        },
        MuiCard: {
            root: {
                // borderWidth: '0px !important', // FIXME: conflicts in async card and new quote
            },
        },
        MuiCardContent: {
            root: {
                padding: '10px !important',
                '&:last-child': {
                    paddingBottom: defaultTheme.spacing(2),
                },
            },
        },
        MuiButton: {
            root: {
                color: '#FFF',
                fontWeight: typographyTheme.typography.fontWeightBold,
                textTransform: 'none',
            },
            contained: {
                boxShadow: 'none',
            },
            outlinedPrimary: {
                backgroundColor: colorTheme.palette.background.default,
                borderWidth: '1.75px',
                borderColor: colorTheme.palette.primary.main,
            },
            outlined: {
                background: '#FFF',
                borderColor: colorTheme.palette.divider,
            },
        },
        MuiInputBase: {
            input: {
                [defaultTheme.breakpoints.only('xs')]: {
                    fontSize: '0.875rem',
                },
            },
        },
        MuiFormLabel: {
            root: {
                [defaultTheme.breakpoints.only('xs')]: {
                    fontSize: '0.875rem',
                },
                color: colorTheme.palette.primary.main,
                '&$focused': {
                    color: colorTheme.palette.primary.main,
                },
            },
        },
        MuiOutlinedInput: {
            root: {
                background: '#FFF',
                color: colorTheme.palette.primary.main,
                '&:hover': {
                    '& $notchedOutline': {
                        borderColor: colorTheme.palette.primary.main,
                    },
                },
            },
            notchedOutline: {
                borderColor: colorTheme.palette.divider,
            },
        },
        MuiExpansionPanel: {
            root: {
                boxShadow: '3px 3px 5px rgba(172, 140, 142, 0.24)', // FIXME: conflicts in async card and new quote
                '&::before': {
                    content: 'none',
                },
            },
            rounded: {
                borderRadius: shapeTheme.shape.borderRadius,
            },
        },
        MuiExpansionPanelSummary: {
            root: {
                [defaultTheme.breakpoints.down('sm')]: {
                    height: '42px !important',
                    minHeight: 'unset !important',
                },
                backgroundColor: colorTheme.palette.primary.main,
                color: '#FFF',
                borderRadius: shapeTheme.shape.borderRadius,
                '&.Mui-expanded': {
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                },
            },
            content: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            },
            expandIcon: {
                color: '#FFF',
            },
        },
        MuiTabs: {
            root: {
                borderBottom: `1px solid ${colorTheme.palette.divider}`,
            },
        },
        MuiTab: {
            root: {
                fontWeight: typographyTheme.typography.fontWeightBold,
            },
        },
        MuiTableHead: {
            root: {
                background: colorTheme.palette.primary.light,
            },
        },
        MuiTableCell: {
            root: {
                fontSize: '0.875rem',
                fontFamily: 'Manrope, sans-serif',
                fontWeight: 500,
                padding: defaultTheme.spacing(2),
                [defaultTheme.breakpoints.down('lg')]: {
                    padding: defaultTheme.spacing(0.8),
                    fontSize: '0.75rem',
                },
                '@media print': {
                    fontSize: '11px',
                },
            },
            head: {
                color: colorTheme.palette.primary.dark,
                fontWeight: typographyTheme.typography.fontWeightBold,
            },
            sizeSmall: {
                '@media print': {
                    padding: defaultTheme.spacing(1),
                },
            },
        },
        MuiTableSortLabel: {
            icon: {
                color: '#FFF !important',
            },
            root: {
                color: '#FFF',
                '&:hover': {
                    color: '#FFF',
                },
                '&$active': {
                    color: '#FFF',
                    '&:hover': {
                        color: '#FFF',
                    },
                },
            },
        },
        MuiDialogTitle: {
            root: {
                padding: defaultTheme.spacing(2),
                paddingBottom: defaultTheme.spacing(1),
                color: colorTheme.palette.primary.main,
                fontWeight: typographyTheme.typography.fontWeightBold,
            },
        },
        MuiDialogContent: {
            root: {
                padding: `${defaultTheme.spacing(1)}px ${defaultTheme.spacing(2)}px`,
            },
        },
        MuiDialogActions: {
            root: {
                padding: defaultTheme.spacing(2),
                paddingTop: defaultTheme.spacing(1),
                backgroundColor: colorTheme.palette.primary.main,
                '& button': {
                    color: '#FFF',
                },
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 0,
                marginRight: defaultTheme.spacing(1),
                color: 'inherit',
            },
        },
    },
})

export const greenHeaderStyle = makeStyles((theme: Theme) => createStyles({
    tableHead: {
        '&.MuiTableHead-root': {
            background: theme.palette.primary.main,
        },
    },
    tableRowHead: {
        '& > *': {
            '&.MuiTableCell-head': {
                color: '#FFF !important',
            },
        },
    },
    tableCellHead: {
        '&.MuiTableCell-head': {
            color: '#FFF !important',
        },
    },
}))

type VictoryThemeDefinition = typeof VictoryTheme.material
export const victoryTheme: VictoryThemeDefinition = {
    ...VictoryTheme.material,
    axis: {
        ...VictoryTheme.material.axis,
        style: {
            ...VictoryTheme.material.axis?.style,
            axis: {
                ...VictoryTheme.material.axis?.style?.axis,
                stroke: colorTheme.palette.grey[300],
            },
            grid: {
                ...VictoryTheme.material.axis?.style?.grid,
                stroke: colorTheme.palette.grey[300],
                strokeDasharray: 10,
            },
            ticks: {
                ...VictoryTheme.material.axis?.style?.ticks,
                stroke: colorTheme.palette.grey[300],
            },
            tickLabels: {
                ...VictoryTheme.material.axis?.style?.tickLabels,
                fontFamily: typographyTheme.typography.fontFamily,
                fill: colorTheme.palette.grey[500],
            },
        },
    },
    line: {
        ...VictoryTheme.material.line,
        style: {
            ...VictoryTheme.material.line?.style,
            data: {
                ...VictoryTheme.material.line?.style?.data,
                stroke: colorTheme.palette.secondary.main,
            },
        },
    },
    area: {
        ...VictoryTheme.material.area,
        style: {
            ...VictoryTheme.material.area?.style,
            data: {
                ...VictoryTheme.material.area?.style?.data,
                fill: fade(colorTheme.palette.secondary.main, 0.1),
            },
        },
    },
    legend: {
        ...VictoryTheme.material.legend,
        style: {
            ...VictoryTheme.material.legend?.style,
            labels: {
                ...VictoryTheme.material.legend?.style?.labels,
                fontFamily: typographyTheme.typography.fontFamily,
                fill: colorTheme.palette.secondary.light,
            },
            title: {
                ...VictoryTheme.material.legend?.style?.title,
                fontFamily: typographyTheme.typography.fontFamily,
                fontWeight: typographyTheme.typography.fontWeightBold,
                fill: colorTheme.palette.secondary.light,
            },
        },
    },
    tooltip: {
        ...VictoryTheme.material.tooltip,
        style: {
            ...VictoryTheme.material.tooltip?.style,
            fontFamily: typographyTheme.typography.fontFamily,
            fontWeight: typographyTheme.typography.fontWeightBold,
            fill: colorTheme.palette.common.white,
        },
        flyoutStyle: {
            ...VictoryTheme.material.tooltip?.flyoutStyle,
            strokeWidth: 0,
            fill: colorTheme.palette.grey[500],
        },
    },
}
